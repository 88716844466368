<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="期限設定" @edit="modal.edit = true" />
    <div>
      <BaseElForm
        v-model="formData"
        label-position="left"
        label-width="250px"
      >
        <BaseElFormItem label="有效期限" prop="daysUntilExpiration">
          <div class="secret-text collapse">
            <span>{{ `${formData.daysUntilExpiration}天` }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="到期通知發送天數設定" prop="daysDiffExpirationNotify">
          <div class="secret-text collapse">
            <span>{{ `${formData.daysDiffExpirationNotify}天` }}</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <PointExpiryEditModal
      v-if="modal.edit"
      :data="formData"
      @close="modal.edit = false"
      @refresh="getShopPointYearExpiration"
    />
  </section>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import PointExpiryEditModal from './PointExpiryEditModal.vue'
import { GetShopPointYearExpiration } from '@/api/shopPoint'
import store from '@/store'

export default defineComponent({
  name: 'PointExpirySetting',
  components: { PointExpiryEditModal },
  setup () {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(null)
    const formData = ref({})
    const modal = reactive({
      edit: false,
    })

    const getShopPointYearExpiration = async () => {
      loading.value = true
      const [res, err] = await GetShopPointYearExpiration({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      formData.value = res
    }

    onMounted(() => {
      getShopPointYearExpiration()
    })
    return {
      modal,
      loading,
      formData,
      getShopPointYearExpiration,
    }
  },
})
</script>
